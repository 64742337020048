.menu {
  position: fixed;
  top: 7rem;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 10;
  transform: translateX(100%);
  transition: transform .3s ease-in-out;
  &.is-active {
    transform: translateX(0);
    .menu__overlay {
      background: rgba(#000, .5);
      transition: background .3s .3s; } }
  &__in {
    position: relative;
    z-index: 2;
    float: right;
    padding-top: 2rem;
    height: 100%;
    width: 24rem;
    overflow-y: auto;
    overflow-x: hidden;
    background: #38824c; }
  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: none;
    transition: none; }
  a {
    display: block;
    padding: 1rem 3rem;
    color: #fff;
    text-transform: uppercase; } }
