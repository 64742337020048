$text: #525252;
$main: #4da265;
$main-hover: darken(#4da265, 10%);
$light: #f9f9f9;
$border: #cdcdcc;
$border-light: #f0f0f0;
$border-dark: #e1e1e1;
$circle: #f6faf7;



@include font(MyriadPro-Regular, MyriadPro-Regular);
@mixin reg {
  font-family: 'MyriadPro-Regular', 'Roboto', 'SF UI Text', Arial, sans-serif; }

@include font(MyriadPro-Bold, MyriadPro-Bold);
@mixin bold {
  font-family: 'MyriadPro-Bold', 'Roboto', 'SF UI Text', Arial, sans-serif; }

@include font(MyriadPro-Semibold, MyriadPro-Semibold);
@mixin sbold {
  font-family: 'MyriadPro-Semibold', 'Roboto', 'SF UI Text', Arial, sans-serif; }

@mixin shadow {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4); }

@mixin round {
  border-radius: 4px; }

@mixin circle {
  border-radius: 50%; }

@mixin gradient {
  background-image: linear-gradient(to top, #3f8c54 0%, #7ed495 100%); }

@mixin gradient-h {
  background-image: linear-gradient(to bottom, #3f8c54 0%, #7ed495 100%); }
