html {
  font-size: 62.5%; }

body {
  color: $text;
  @include reg;
  font-size: 1.4rem;
  line-height: 1.71; }

a {
  text-decoration: none; }

.hidden-mob {
  @include r(480) {
    display: none; } }

.out {
  overflow: hidden;
  padding-top: 7rem; }

.l-center {
  padding: 0 3rem;
  @include r(480) {
    padding: 0 1.5rem; } }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.row {
  margin: 0 -.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @include clr;
  > [class^="col-"] {
      padding: 0 .5rem; } }

@for $i from 1 through 12 {
  .col-#{$i} {
    width: (100%/12)*$i; }
  .col-#{$i}-mob {
    @include r(480) {
      width: (100%/12)*$i; } } }

.col-center {
  align-self: center; }
.col-bottom {
  align-self: flex-end; }

.section {
  padding: 3rem 0 0;
  + .footer {
    margin-top: 3.2rem; } }

.head {
  margin: 0 0 2.3rem;
  padding: 2.4rem 0 1.9rem;
  text-align: center;
  background: #d5d5d5 url(../img/head.png) 0 0 repeat;
  .btn-action {
    margin: 1.6rem 0 1rem; }
  + form {
    .table-wrap {
      margin-top: -2.3rem; } } }

.top {
  background: #f9f9f9;
  text-align: center;
  text-transform: uppercase;
  padding: 1.5rem 0 1.2rem;
  &__link {
    display: inline-block;
    color: $text;
    padding: .5rem 2rem .2rem;
    border-left: 2px solid $text;
    line-height: 1;
    transition: color .3s;
    &:hover {
      color: $main; }
    &:first-child {
      border-left: none; } }
  + .subscribe-section {
    margin-top: 0; } }

.page-title {
  text-align: center;
  font-size: 2.2rem;
  @include bold;
  text-transform: uppercase;
  line-height: 1.27;
  @include r(480) {
    font-size: 1.8rem; }
  strong {
    color: #3d8a51;
    font-weight: normal; } }

.subtitle {
  margin: 1rem 0 .7rem;
  font-size: 1.4rem;
  @include sbold;
  line-height: 1.7;
  text-align: center;
  strong {
    color: $main;
    font-weight: normal; } }

.title {
  margin: 2rem 0;
  color: $text;
  font-size: 1.8rem;
  @include bold;
  line-height: 1.56;
  text-transform: uppercase;
  text-align: center;
  + .steps {
    margin-top: 2rem; }
  + .title-info {
    margin-top: -2rem; }
  &_small {
    font-size: 1.6rem; } }

.title-info {
  color: $text;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center; }

.title-underlined {
  text-decoration: underline;
  font-size: 1.6rem;
  @include sbold;
  text-transform: uppercase; }

.article {
  margin: 0 0 4rem;
  p {
    margin: 1.7rem 0; }
  a {
    display: inline-block;
    @include bold;
    font-size: 1.4rem;
    color: $main;
    &:hover {
      color: $main-hover; }
    &[href^="tel:"] {
      color: $text;
      &:hover {
        color: $main; } } } }

.subscribe-section {
  margin-top: 4rem;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #d6d4d4;
  padding: 2.7rem 0 3.5rem;
  text-align: center;
  background: #f9f8f6 url(../img/subscribe.png) 0 0 repeat;
  + .footer {
    margin-top: 0; }
  &_simple {
    padding: 1.5rem 0 0; } }

.subscribe {
  position: relative;
  margin: 1.8rem 0 2.7rem;
  &__input {
    display: block;
    border: 1px solid #c2c2c2;
    padding: 0 10.5rem 0 1.5rem;
    width: 100%;
    height: 5rem;
    border-radius: 2px;
    color: $text;
    @include sbold;
    font-size: 1.4rem;
    background: none;
    text-align: center;
    @include placeholder {
      color: rgba($text, .6); }
    @include r(480) {
      text-align: left; } }
  &__btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 2.5rem;
    height: 5rem;
    line-height: 5rem;
    color: #fffefe;
    font-size: 1.4rem;
    @include sbold;
    text-align: center;
    @include gradient;
    border-radius: 0 3px 3px 0;
    &:hover {
      @include gradient-h; } } }

.soc {
  font-size: 0;
  a {
    display: inline-block;
    margin: 0 .6rem;
    width: 6.2rem;
    height: 6.2rem;
    line-height: 6.2rem;
    text-align: center;
    border-radius: 3px;
    background: $border-dark;
    color: $text;
    &:hover {
      color: #fff; } }
  &__fb {
    &:hover {
      background: #365899; } }
  &__pt {
    &:hover {
      background: #bd081c; } }
  &__tw {
    &:hover {
      background: #1da1f2; } }
  .fa {
    vertical-align: middle;
    font-size: 2.3rem; } }

.slider {
  position: relative;
  margin: 2.8rem 0 3.2rem;
  text-align: center;
  &__main {
    position: relative; }
  .slick-slide {
    padding: 0 5px; }
  .slick-slider {
    a {
      display: block;
      border: 1px solid $border-dark;
      padding: 52% 0;
      position: relative;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } } }

  .slider-prev {
    left: -3.5rem;
    @include r(480) {
      left: -1.5rem; } }
  .slider-next {
    right: -3.5rem;
    @include r(480) {
      right: -1.5rem; } }
  &__link {
    color: $text;
    font-size: 1.4rem;
    @include sbold;
    text-transform: uppercase; } }

.slick-dots {
  margin: 1.5rem 0;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .6rem; }
  button {
    position: relative;
    @include hide-text;
    width: 1.6rem;
    height: 1.6rem;
    @include circle;
    border: 4px solid #c4dccb;
    background: #c4dccb;
    transition: background .3s; }

  .slick-active {
    button {
      background: #3d8a52; } } }
//&:before
//  content: ''
//  position: absolute
//  left: 50%
//  top: 50%
//  transform: translate(-50%, -50%)
//  width: 0.8rem
//  height: 0.8rem
//  background: #3d8a52
//  +circle
.slider-prev,
.slider-next {
  position: absolute;
  top: 0;
  height: 100%;
  width: 3.5rem;
  background: #ebebeb;
  &:hover,
  &:active {
    .fa {
      color: $main; } }
  @include r(480) {
    width: 1.5rem; }
  .fa {
    font-size: 1.4rem;
    transition: color .3s; } }

.cells-wrap {
  margin-bottom: 2.5rem; }

.cell {
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.8rem;
  border: 0.1rem dashed rgba(#3a854e, 0.2);
  color: $text;
  font-size: 1.6rem;
  @include sbold;
  .fa {
    margin-right: 1.5rem;
    color: #3b8750; }
  span {
    width: 240px; } }

.important {
  text-align: center;
  padding: .7rem 0 0;
  font-size: 1.4rem;
  @include sbold;
  line-height: 1.29;
  color: $text;
  span, a {
    color: $main; }
  a {
    text-decoration: underline; }
  &_big {
    font-size: 1.6rem;
    strong {
      font-size: 1.8rem; } } }

.post {
  border-bottom: 1px solid #f0f0f0;
  padding: 3rem 0 3.5rem;
  img {
    display: block;
    margin: 1.2rem 0 1.5rem;
    width: 100%;
    height: auto; }
  &__name {
    display: block;
    line-height: 1.5;
    @include bold;
    font-size: 1.8rem;
    color: $text;
    transition: color .3s;
    &:hover {
      color: $main; } }

  &__more {
    color: $text;
    display: block;
    transition: color .3s;
    @include bold;
    text-transform: uppercase;
    &:hover {
      color: $main; }
    i {
      margin-left: 1.5rem;
      display: inline-block; } } }

.text {
  @include reg;
  line-height: 1.7;
  margin-bottom: 2rem;
  p {
    margin-bottom: 1rem; }
  strong {
    @include bold; } }

.meta {
  font-size: 1.4rem;
  display: inline-block;
  vertical-align: middle;
  margin: 1rem 0 1.5rem;
  @include reg;
  span {
    display: inline-block;
    vertical-align: middle;
    @include bold; }
  i {
    color: $text;
    font-size: 1.2rem;
    margin-right: 1rem;
    display: inline-block; } }

.border-section {
  border-bottom: 1px solid $border-light;
  padding: 2.2rem 0 2rem;
  text-align: center; }

.link {
  display: inline-block;
  text-transform: uppercase;
  color: $text;
  font-size: 1.4rem;
  line-height: 1;
  span {
    border-bottom: 1px solid $text;
    transition: border .3s; }
  &:hover {
    span {
      border-color: transparent; } } }

.margin-wrap {
  margin-top: 3.8rem; }

.link-framed {
  display: flex;
  align-items: center;
  padding: 1.2rem 2.5rem 1.1rem;
  margin: 1.2rem 0;
  min-height: 7rem;
  text-align: left;
  border: 1px solid $border-light;
  @include sbold;
  color: $text;
  font-size: 1.4rem;
  line-height: 1.7;
  &:hover {
    color: $main; }
  i {
    margin-left: 1rem; } }
.link-border {
  padding: 1.2rem 2.5rem 1.1rem;
  margin: 5rem 0;
  border: 1px solid $border-light;
  display: block;
  &__title {
    color: $main;
    font-size: 1.8rem;
    @include sbold;
    display: block;
    text-align: center; }
  &__tip {
    display: block;
    text-align: center;
    color: $text;
    @include reg; } }

.steps {
  margin: 5.5rem 0 0;
  font-size: 1.4rem;
  line-height: 1.29;
  color: $text;
  @include sbold;
  @include clr;
  counter-reset: list;
  @include r(480) {
    @include reg; }
  &__item {
    position: relative;
    margin-bottom: 3.2rem;
    display: flex;
    align-items: center;
    border: 0.1rem dashed rgba($main, .5);
    min-height: 5.7rem;
    border-radius: 5.7rem;
    &:nth-child(2n) {
      margin-right: 2rem;
      padding: 1rem 8.8rem .8rem 4rem;
      @include r(480) {
        padding-left: 2rem;
        padding-right: 3rem; }
      &:before {
        right: -2rem; } }
    &:nth-child(2n+1) {
      margin-left: 2rem;
      padding: 1rem 4rem .8rem 8.8rem;
      @include r(480) {
        padding-right: 2rem;
        padding-left: 3rem; }
      &:before {
        left: -2rem; } }
    &:before {
      content: '0' counter(list);
      counter-increment: list;
      position: absolute;
      top: 50%;
      width: 7.6rem;
      height: 7.6rem;
      line-height: 7.6rem;
      text-align: center;
      background: $main;
      @include circle;
      transform: translateY(-50%);
      font-size: 2.4rem;
      color: #fff;
      @include r(480) {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 1.2rem; } }
    &:nth-child(n+10) {
      &:before {
        content: counter(list); } } }

  strong {
    @include bold;
    color: $main; } }

.search {
  position: relative;
  height: 6rem;
  background: #ececec;
  &__input {
    display: block;
    padding: 2.4rem 1.5rem 1.8rem;
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    @include sbold;
    text-transform: uppercase;
    background: #ececec;
    @include placeholder {
      color: rgba($text, .56); }
    &:focus {
      background: lighten(#ececec, 5%); }
    @include r(480) {
      text-align: left; } }
  &__btn {
    position: absolute;
    right: 6rem;
    top: 0;
    height: 100%;
    width: 4rem;
    background: none;
    @include r(480) {
      width: 6rem;
      right: 0; }
    .fa {
      font-size: 2.3rem;
      color: #3d8a52; } } }

.items-wrap {
  margin: 3rem 0 0; }

.item {
  display: flex;
  margin: 0 0 1.5rem;
  align-items: stretch;
  border: 1px solid $border-dark;
  min-height: 10.5rem;
  color: $text;
  + .btn-action {
      margin-top: 2rem; }
  &__img {
    border-right: 1px solid $border-dark;
    padding: 1rem;
    width: 9.8rem;
    min-width: 9.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebebeb;
    img {
      display: block;
      max-height: 76px; } }
  &__open {
    border-left: 1px solid $border-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.2rem;
    min-width: 6.2rem;
    background: #ebebeb;
    .fa {
      font-size: 1.8rem; } }
  &__main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 auto;
    padding: 1rem 3rem;
    font-size: 1.6rem;
    @include sbold;
    @include r(480) {
      padding: 1rem 1.5rem;
      font-size: 1.2rem; } }
  &__name {
    display: block;
    margin-bottom: .2rem;
    font-size: 1.8rem;
    @include bold;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.3;
    @include r(480) {
      font-size: 1.4rem; } }
  &_template {
    .item__img {
      background: #fff; } }
  &_img {
    margin: 1.7rem auto 2rem;
    width: 14.2rem;
    height: 11.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 80%;
      max-height: 82px;
      object-fit: cover; } } }

.review {
  padding-top: 2.5rem;
  border-bottom: 2px solid $border-dark;
  margin-bottom: 1rem;
  &_positive {
    border-color: #3f8d55; }
  &:last-child {
    margin-bottom: 4rem; }
  .link {
    display: block;
    @include bold; }
  &__main {
    position: relative;
    padding: 2.5rem 0 10rem; }
  .slider-prev,
  .slider-next {
    bottom: 2.5rem;
    top: auto;
    height: 5rem;
    width: 5rem;
    .fa {
      font-size: 2.2rem; } }
  .slider-prev {
    left: 0; }
  .slider-next {
    left: 6.4rem; }
  .slick-dots {
    left: auto;
    right: 0;
    bottom: 2.5rem;
    position: absolute; }
  &__name {
    @include bold;
    margin-bottom: 1rem; }
  .rate {
    margin-bottom: 1.6rem; } }

.border-wrap {
  border-bottom: 2px solid $border-dark; }

.img-item {
  padding-top: 4.5rem;
  &:last-child {
    padding-bottom: 2.5rem;
    border-bottom: 1px solid $border-dark; }
  &__img {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 0;
    img {
      max-width: 70%; } }
  &__text {
    line-height: 1.7rem;
    font-size: 1.4rem;
    @include sbold; } }

.mention {
  margin-top: 3.5rem;
  &__marked {
    @include bold; }
  &__info {
    @include reg; }
  &__text {
    @include sbold;
    margin-top: 1.3rem; } }

.benefit {
  margin-top: 4rem;
  &__item {
    margin-bottom: 2.8rem; }
  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem; }
  &__number {
    margin-right: 1.2rem;
    height: 5rem;
    width: 5rem;
    min-width: 5rem;
    text-align: center;
    line-height: 1;
    @include circle;
    background: $circle;
    @include vertical;
    @include bold;
    span {
      font-size: 1.8rem;
      line-height: 1;
      color: #3b864f;
      display: inline-block;
      vertical-align: middle;
      margin-top: .3rem; } } }

.rate {
  font-size: 0;
  color: #3b864f;
  .fa {
    font-size: 1.3rem;
    margin-right: 1rem; } }

.custom-body {
  display: none;
  .subtitle {
    margin-top: 0; } }

// TODO animation
.loader {
  margin: 2.8rem 0 -1.3rem;
  text-align: center;
  font-size: 1.4rem;
  @include sbold; }


.section-cart {
  border-top: 4px solid $border-light;
  padding: 3rem 0 2.5rem; }

.badge {
  display: inline-block;
  padding: .9rem 1.6rem .4rem;
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.4rem;
  @include sbold;
  color: #fff;
  &_free {
    background: #3c8951; } }


.form-action {
  margin-top: 4rem; }

.form-title {
  margin: 0 0 .7rem;
  font-size: 1.6rem;
  @include sbold; }

.total {
  margin-bottom: 5rem;
  border-top: 1px solid $border-light;
  padding-top: 2rem;
  color: $text;
  font-size: 1.8rem;
  text-align: right;
  strong {
    @include sbold; }
  &_small {
    margin-bottom: 2.5rem;
    padding-top: 1rem;
    font-size: 1.4rem; } }

.payment {
  &__icons {
    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.5rem;
      font-size: 1.4rem;
      @include sbold; }
    .fa {
      display: inline-block;
      vertical-align: middle;
      margin-right: .3rem;
      font-size: 2rem;
      color: #3c8951; } }
  .btn-back {
    margin-top: 3.7rem; } }

.inline-info {
  border-bottom: 1px solid $border;
  padding: .5rem 1rem;
  margin-bottom: 2.5rem;
  font-size: 1.4rem;
  &__title,
  &__value {
    display: inline-block; }
  &__value {
    margin-left: 2rem;
    @include sbold; } }

