.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-image: linear-gradient(to bottom, #419056, #38824c);
  .l-center {
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &__links {
    width: 11rem;
    .btn-link {
      &:first-child {
        margin-top: 3px;
        &:last-child {
          margin-top: 0;
          height: 4rem;
          line-height: 4rem; } } } } }

.logo {
  display: inline-block;
  img {
    display: block; } }

.burger {
  width: 5rem;
  height: 5rem;
  line-height: 1;
  font-size: 0;
  background: rgba(#fff, .1);
  border-radius: .4rem;
  &.is-active {
    i {
      background: none;
      &:before {
        transform: translateY(.9rem) rotate(45deg); }
      &:after {
        transform: translateY(-.9rem) rotate(-45deg); } } }

  i {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    height: 2px;
    width: 2.5rem;
    background: #fff;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      height: 2px;
      background: #fff;
      width: 2.5rem;
      transition: transform .3s; }
    &:before {
      top: -.9rem; }
    &:after {
      bottom: -.9rem; } } }

.btn-link {
  display: block;
  margin: .5rem 0;
  padding: 0 .5rem;
  height: 2.5rem;
  line-height: 2.7rem;
  border-radius: 3px;
  background: rgba(#fff, .1);
  font-size: 1.4rem;
  @include reg;
  text-align: center;
  color: #fff;
  &:hover {
    background: rgba(#fff, .2); } }
