.field {
  display: block;
  width: 100%;
  margin-bottom: 1.8rem;
  position: relative;
  &__title {
    display: block;
    margin-bottom: .6rem;
    &_bold {
      @include sbold; } } }

.input {
  display: block;
  align-items: center;
  padding: 1.7rem 2.2rem 1.3rem 1.5rem;
  width: 100%;
  height: 5rem;
  background: $light;
  border: 1px solid $border;
  font-size: 1.4rem;
  color: darken($text, 20%);
  @include reg;
  line-height: 1;
  transition: color, border, background, .3s;
  border-radius: 0;
  @include placeholder {
    color: rgba($text, .8); }
  &:focus {
    border-color: darken($border, 15%);
    background: lighten($light, 1%); }
  &[readonly] {
    color: $text; }
  &_textarea {
    padding: 1.8rem 1.5rem;
    height: 18rem;
    resize: none; }
  &_white {
    background: #fff;
    @include placeholder {
      color: $text; }
    &:focus {
      background: #fff; } }
  &_small {
    padding: .9rem 2.2rem .6rem 1.5rem;
    height: 4rem; }
  &.is-empty {
    color: rgba($text, .8); } }

.select {
  position: relative;
  display: block;
  width: 100%;
  select {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    -webkit-appearance: none;
    opacity: 0;
    box-shadow: none; }
  .input {
    position: relative;
    padding-top: 1.9rem;
    @include text-overflow;
    &_small {
      padding-top: 1.2rem; }
    &:before {
      content: "\f107";
      position: absolute;
      top: 50%;
      margin-top: -.7rem;
      right: 1.3rem;
      font-size: 1.4rem;
      color: $text;
      @include fa-icon;
      @include r(480) {
        right: 1.5rem; } } }
  &__title {
    display: block;
    margin-bottom: .8rem;
    font-size: 1.4rem;
    @include sbold; } }

.files-wrap {
  padding: 0 0 1.7rem;
  .file {
    &_soc {
      margin-bottom: 3rem; } } }

.file {
  overflow: hidden;
  position: relative;
  margin-bottom: 3.5rem;
  &:hover {
    .file__btn {
      background: darken(#f3f3f3, 10%); } }
  &__btn {
    display: inline-block;
    padding: 1.2rem .8rem .8rem;
    min-width: 17rem;
    line-height: 1;
    text-decoration: none;
    @include sbold;
    color: $text;
    background: #f3f3f3;
    transition: background .3s;
    font-size: 1.4rem;
    text-transform: uppercase;
    text-align: center;
    @include r(480) {
      padding-left: 2rem;
      padding-right: 2rem; } }
  &__input {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 100px;
    opacity: 0; }
  &__title {
    display: inline-block;
    margin-left: 1rem;
    opacity: .6;
    @include sbold;
    font-size: 1.4rem; }
  &_block {
    margin-bottom: 1.5rem; }
  &_soc {
    .file__btn {
      padding: 1.4rem .8rem 1.2rem; }
    .file__title {
      @include r(480) {
        display: block;
        margin-top: 1rem; } }
    .fa {
      margin-right: 1rem;
      font-size: 1.5rem; } }
  &_fb {
    &:hover {
      .file__btn {
        background: darken(#3b5998, 10%); } }
    .file__btn {
      background: #3b5998;
      color: #fff; } } }

.file-name {
  display: block;
  margin: 1.5rem 0;
  width: 100%;
  height: 4rem;
  text-align: center;
  line-height: 4rem;
  background: #f9f9f9;
  font-size: 1.4rem;
  color: rgba($text, .8);
  @include sbold; }

.pager {
  text-align: center;
  display: block;
  margin: 3.2rem auto 4.4rem;
  font-size: 0;
  &__btn {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-size: 1.4rem;
    border: 1px solid #f1f1f1;
    border-right: none;
    a {
      padding: .8rem 1.7rem;
      display: block;
      line-height: 1;
      color: $text;
      transition: bakground, color, .3s;
      max-height: 3rem;
      @include r(480) {
        padding: .8rem 1.2rem; }
      &:hover {
        background: $light; } }
    &.is-outside {
      a {
        padding: .8rem 1.4rem;
        line-height: 1;
        @include r(480) {
          padding: .8rem 1.2rem; }
        &:hover {
          color: $main; } } }
    &.is-active {
      a {
        background: $light; } }
    &:last-child {
      border-right: 1px solid #f1f1f1; } } }

.btn-wrap {
  margin: 4rem 0 0;
  &_small {
    margin: 2.2rem 0 0; } }

.btn-action {
  display: block;
  padding: 2.2rem 1rem 2rem;
  width: 100%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  text-shadow: 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.06);
  color: #fff;
  font-size: 1.8rem;
  line-height: 1;
  @include bold;
  text-transform: uppercase;
  border-radius: 3px;
  background-image: linear-gradient(to top, #fd3601, #fd8e01);
  &:hover {
    background-image: linear-gradient(to bottom, #fd3601, #fd8e01);
    box-shadow: none; }
  &_second {
    background-image: linear-gradient(to top, #e1e1e1, #fcfcfc);
    box-shadow: none;
    color: $text;
    border: 1px solid #e1e1e1;
    &:hover {
      background-image: linear-gradient(to bottom, #e1e1e1, #fcfcfc); } }
  &_third {
    background-image: linear-gradient(to top, #3f8c54 0%, #7ed495 100%);
    box-shadow: none;
    &:hover {
      background-image: linear-gradient(to bottom, #3f8c54 0%, #7ed495 100%); } }
  &_fluid {
    display: block;
    width: 100%;
    text-align: center; }
  &_small {
    padding: 2.3rem 1rem 1.9rem;
    font-size: 1.4rem; }
  &_auto {
    padding: 1.4rem 3.5rem 1.3rem;
    width: auto;
    @include sbold; }
  + .btn-action {
    margin-top: 1.8rem; }
  .fa {
    margin: 0 .5rem;
    font-size: 1.9rem; } }

.btn-plain {
  display: inline-block;
  border: 1px solid $border-dark;
  padding: 2.4rem .5rem 1.8rem;
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  color: $text;
  line-height: 1;
  @include bold;
  text-transform: uppercase; }

.steps-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4rem;
  padding: 0 4rem;
  height: 6.9rem;
  background: #f1f1f1;
  @include r(480) {
    padding: 0 1.5rem; }
  &_push {
    margin-bottom: -5rem; }
  &__link {
    font-size: 1.8rem;
    @include sbold;
    color: $text;
    .fa {
      margin-right: 1.5rem; } } }

.panel-wrap {
  margin-bottom: 1.7rem;
  .radio-wrap {
    margin: 2rem 0; }
  .panel {
    margin: 2rem 0 0; } }

.panel {
  margin: 3rem 0 2.5rem;
  padding: 2.1rem 2.5rem 3.5rem;
  background: #f2f2f2;
  .title {
    margin-top: 0; }
  .field {
    margin-bottom: 2rem; }
  .btn-action {
    margin-top: 2.5rem; }
  &_fields {
    padding-bottom: 2.1rem;
    .field {
      &:last-child {
        margin-bottom: 0; } } } }

.add {
  position: relative;
  margin: 3.7rem 0;
  display: flex;
  align-items: center;
  padding: 1rem 5rem 1rem 3rem;
  min-height: 7rem;
  border: 1px solid #e1e1e1;
  background: #f5f5f5;
  font-size: 1.4rem;
  color: $text;
  transition: border, box-shadow, .3s;
  strong {
    display: block;
    font-weight: normal;
    text-transform: uppercase;
    @include bold; }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 3rem;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 2px;
    background: #39844d; }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 3.6rem;
    transform: translateY(-50%);
    background: #39844d;
    width: 2px;
    height: 1.6rem;
    transition: height .2s; }
  &.is-active {
    box-shadow: inset 0 0 0 1px #39844d;
    border-color: #39844d;
    &:after {
      height: 2px; } } }

.checkbox {
  display: block;
  position: relative;
  padding: .5rem 0 .5rem 4.1rem;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 2px;
    background: #ebebeb; }
  input {
    display: none;
    &:checked {
      + .fa {
        display: block; } } }
  &__text {
    display: block;
    color: $text;
    font-size: 1.4rem;
    @include sbold; }
  .fa {
    position: absolute;
    left: .8rem;
    top: .8rem;
    display: none; } }

.radio-wrap {
  margin: 2.5rem 0;
  text-align: center;
  &_justify {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .radio {
      &__text {
        font-size: 1.2rem; } } }
  &_left {
    text-align: left; }
  .radio_inline {
    margin-bottom: .5rem; } }

.radio-group {
  margin: 2.2rem 0; }

.radio {
  display: block;
  position: relative;
  &_inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: 2.5rem;
    @include r(480) {
      margin-right: 1rem; }
    &:last-child {
      margin-right: 0; } }
  input {
    display: none;
    &:checked {
      + .radio__text {
        &:after {
          display: block; } } } }
  &__text {
    display: block;
    position: relative;
    padding-left: 2.8rem;
    color: $text;
    font-size: 1.4rem;
    @include sbold;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-image: linear-gradient(to top, #b2b2b2 0%, #e0e0e0 100%); }
    &:after {
      display: none;
      content: '';
      position: absolute;
      top: .6rem;
      left: .6rem;
      width: .8rem;
      height: .8rem;
      background: #3c8951;
      border-radius: 50%; } }
  &_regular {
    .radio__text {}
    @include reg; } }

.table-wrap {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5rem;
    background: #f1f1f1; } }

.table {
  position: relative;
  z-index: 2;
  line-height: 1.3;
  th {
    padding: 0 2.2rem;
    height: 5rem;
    vertical-align: middle;
    background: #f1f1f1;
    @include sbold;
    text-align: left;
    @include r(480) {
      padding: 0 1rem; }
    &:first-child {
      padding-left: 0; }
    &:last-child {
      padding-right: 0;
      text-align: right; } }
  td {
    padding: 2.2rem;
    vertical-align: middle;
    @include r(480) {
      padding: 1rem; }
    &:first-child {
      padding-left: 0;
      &[colspan] {
        text-align: right; } }
    &:last-child {
      padding-right: 0;
      text-align: right; } }
  strong {
    @include sbold; }

  tbody {
    tr {
      border-bottom: 1px solid $border-dark;
      &:last-child {
        border: 0; } } } }
